import React from 'react'

import './slide.css'

const Slide = ({title, subTitle, text, image, sliderId}) => {
  return (
  <div className={`slide ${sliderId}__slide`}>
    <img className="slide-image" src={image} alt={`${title}, ${subTitle}`} />
    <div className="slide-content">
      <div className="slide-content-header">
        <h1>{title}</h1>
        <h3>{subTitle}</h3>
      </div>
      <p>{text}</p>
    </div>
    
  </div>
  )
}

export default Slide