import React from 'react'
import { Link } from 'react-router-dom'
import {IoIosArrowDroprightCircle} from 'react-icons/io'

import {servicesDatas} from '../../datas/servicesDatas'

import './servicesMenu.css'

const ServicesMenu = ({setToggleMenu}) => {
 
  return (
    <div className='servicesMenu'>
      <div className="servicesMenu__button nav-link">
        Services<IoIosArrowDroprightCircle className='servicesMenu__button--icon'/>
      </div>
      <div className='servicesMenu__wrapper'>
        <div className="servicesMenu__items">
          {servicesDatas.map(({id, title, miniDescription}) => 
              <Link key={id} className='servicesMenu__items__link' to={`/service/${id}`} title={miniDescription} onClick={()=>setToggleMenu(false)}> 
                {title}
              </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default ServicesMenu
