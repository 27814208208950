import React from 'react'
import Slider from '../../utils/slider/Slider'
import {teamDatas} from '../../datas/teamDatas'
import './team.css'

const Team = () => {
  return (
    <Slider sliderDatas={teamDatas}/>
  )
}

export default Team