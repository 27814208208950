import React, { useEffect } from 'react'

import  {Banner, HomeServices} from '../../containers'

import './home.css'

function Home(){
  
  useEffect(() => {
    document.title = "Home | D'YVIX"
  }, []);

  const Slogang = () => {
    return(
    <>
      Web. <br/>Hardware. <br/>Software.
    </>)
  }

  const bannerInfos = {
    title: 'D\'Yvix IT Solutions ',
    description: 'Société de Services et d\'Ingénierie Informatique et de Télécommunication',
    slogang: <Slogang/>
  }

  return(
    <div id='home'>
      <Banner itemA={bannerInfos.title} itemB={bannerInfos.description} itemC={bannerInfos.slogang}/>
      <HomeServices/>
    </div>
  )
}

export default Home