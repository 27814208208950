import React, { useState, createContext } from 'react'

export const CommandContext = createContext()

export const CommandProvider = ({children}) => {
  const [wantedServices, setWantedServices] = useState([])

  const addService = (newService) => {
    !wantedServices.includes(newService) && setWantedServices([...wantedServices, newService])
  }

  const removeService = (removedService) => {
    const remainingServices = wantedServices.filter((service) => service.id !== removedService.id)
    wantedServices.includes(removedService) && setWantedServices(remainingServices)
  }

  return(
    <CommandContext.Provider value={{wantedServices, addService, removeService}}>
      {children}
    </CommandContext.Provider>
  )
}