import React, { useEffect } from 'react'
import { AiFillMail, AiOutlineLinkedin} from 'react-icons/ai'
//import {AiFillMail} from 'react-icons/ai'
//import {AiFillMessage} from 'react-icons/ai'
import {AiOutlineWhatsApp} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {BsMessenger} from 'react-icons/bs'


import './contact.css'

const Contact = () => {
  
  useEffect(() => {
    document.title = "Contact | D'YVIX"
  }, []);

  return (
    <div className='contact'>
      <section className='contact-titles'>
        <div className="contact-header">
          <h1>ENVOYEZ NOUS UN MESSAGE</h1>
          <p><p>Nous vous répondrons aussi tôt!</p></p>
        </div>
        <section className="contact-inboxs">
          <a target='_blank' rel='noreferrer' href='https://wa.me/c/237674294455' className="contact-inboxs-item"><AiOutlineWhatsApp className='icon'/></a>
          <a target='_blank' rel='noreferrer' href='https://twitter.com/d_yvix' className="contact-inboxs-item"><AiOutlineTwitter className='icon'/></a>
          <a target='_blank' rel='noreferrer' href='https://www.facebook.com/dyvixitsolutions' className="contact-inboxs-item"><BsMessenger className='icon'/></a>
          <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/dyvix-itsolutions/'  className="contact-inboxs-item"><AiOutlineLinkedin className='icon'/></a>
        </section>
      </section>
      <section className='contact-content'>
        {/*
        <form action="" className='contact__form'>
          <div className="contact-input"><div className="contact-input-icon"><AiFillPhone className='icon'/></div><input placeholder='Numéro de téléphone' type="telephone" /></div>
          <div className="contact-input"><div className="contact-input-icon"><AiFillMail className='icon'/></div><input placeholder='Adresse e-mail' type="email" /></div>
          <div className="contact-textarea"><div className="contact-input-icon"><AiFillMessage className='icon'/></div><textarea placeholder='Votre message ici ...' name="" id="" cols="30" rows="10"></textarea></div>
          <div className="contact-submit"><input type="submit" /></div>
        </form>
        */}
        <div className="contact-header">
          <h1>Envoyez nous un mail !!</h1>
          <p><p>Nous vous attendons avec impatience !</p></p>
        </div>
        <section className="contact-inboxs">
          <a className='contact-inboxs-item' href="mailto:mailto:contact@dyvixitsolutions.com"><AiFillMail className='icon' /></a>
        </section>
        
      </section>
    </div>
  )
}

export default Contact
