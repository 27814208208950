import React from 'react'

import Slider from '../../utils/slider/Slider'
import { testimoniesDatas } from '../../datas/testimoniesDatas'
import './testimonies.css'

const Testimonies = () => {
  return (
    <Slider sliderDatas={testimoniesDatas} sliderId='testimonies'/>
  )
}

export default Testimonies
