import React, { useEffect } from 'react'
import { BiNews } from 'react-icons/bi';

//import { Loader } from '../../utils/style/Atoms.jsx';

import './news.css'

const News = () => {
  
  useEffect(() => {
    document.title = "Actualités | D'YVIX"
  }, []);
  
  /*const [newsData, setNewsData] = useState(null)
  const [isLoading, setLoading] = useState(true) 
  const [error, setError] = useState(false)*/

  /*if (error)*/ return(
    <div className='news__error'>
      <div><h1>OUPS ...</h1></div>
      <BiNews className='news__error-image'/>
      <h1>Une Erreur Est Survenue Lors Du Chargement Des Actualités !</h1>
      <p>Veuillez vérifier votre connexion internet ou essayer plus tard ...</p>
    </div>
  )

  /*return (
    <div className='news'>
      <div className="news__header">
        <h1>Ne ratez aucune news, vous les retrouverez ici !</h1>
      </div>
      <section className="news__container">
      {isLoading ?
        <Loader />
      : (newsData &&
        newsData.map((post, index) => (
          <Link to={"/" + post.slug.current} key={index} className="news__container-item">
              <img className='news__container-item__image' src={post.mainImage.asset.url} alt="" />
              <div className='news__container-item__title'>
                <h2>{post.title}</h2>
              </div>
          </Link>))
        )}
      </section>
    </div>
  )*/
}

export default News
