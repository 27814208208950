import styled from 'styled-components'

export const SlideImage = styled.img` 
  width: 400px;
  height: 300px;
  object-fit: cover;
`

export const StyledSlider = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`