//import React, {useContext} from 'react'
import { useParams } from 'react-router-dom'
//import { CommandContext } from '../../utils/context'
import {AiOutlineWhatsApp} from 'react-icons/ai'
import {servicesDatas} from '../../datas/servicesDatas'

import './service.css'
import { useEffect } from 'react'

const Service = () => {
  

  const {serviceId} = useParams();

  const currentService = servicesDatas.find((service) => service.id === serviceId)

  useEffect(() => {
    document.title = `${currentService.title}`
  }, [currentService]);

//  const {addService} = useContext(CommandContext)

  return (
    <div className='service_page' id={`service-${serviceId}`}>
      <section className="service-top">
        <section className="service-top-image">
          <div className='image'><div><img src={currentService.icon} alt="service" /></div></div>
        </section>
        <section className="service-top-content" id={`id-${serviceId}`}>
          <div className="service-top-content-header">
            <div className='service-top-content__header-bar service-top-content__header-bar_top' />
            <h1>{currentService.title}</h1>
            <div className='service-top-content__header-bar service-top-content__header-bar_bottom' />
            <p>{currentService.miniDescription}</p>
          </div>
          {/*<div className="service-top-content-team">
            <div className="service-top-content-team-member"></div>
            <div className="service-top-content-team-member"></div>
            <div className="service-top-content-team-member"></div>
            <div className="service-top-content-team-member"></div>
            <div className="service-top-content-team-member"></div>
          </div>
          <div className='service-top-content-stats'>
            {currentService.stats}  
  </div>*/}
          <a target='_blank' rel='noreferrer' className="service_page-cta" href='https://wa.me/c/237674294455' onClick={{/*() => addService(currentService)*/}}><AiOutlineWhatsApp className='contact__whatsap-icon'/>Je le veux</a>
        </section>
      </section>
      
    </div>
  )
}

export default Service
