import member1 from '../assets/member1.jpg';
import member2 from '../assets/member2.jpg';
import member3 from '../assets/member1.jpg';
import member4 from '../assets/member2.jpg';
import member5 from '../assets/member1.jpg';
import member6 from '../assets/member2.jpg';


export const teamDatas = [
  {
    title: "name1",
    subTitle: "developpeur web",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : member1
  },
  {
    title: "name2",
    subTitle: "developpeur mobile",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : member2
  },
  {
    title: "name3",
    subTitle: "ux designer",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : member3
  },
  {
    title: "name4",
    subTitle: "administrateur système",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : member4
  },
  {
    title: "name5",
    subTitle: "maintenancier",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : member5
  },
  {
    title: "name6",
    subTitle: "chef du marketing",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : member6
  }
];