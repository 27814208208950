import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { CommandProvider } from './utils/context';
import {Home, About, Contact, News, Service /*,Command*/} from './pages';
import {Header, Footer} from './containers'
import './App.css'
import { StrictMode } from 'react';

const App = () => {
  return (
  <StrictMode className="App">
    <Router>
      <CommandProvider>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/service/:serviceId' element={<Service />}/>
          {/*<Route path='/command' element={<Command/>*/}
        </Routes>
        <Footer/>
      </CommandProvider>
    </Router>
  </StrictMode>
  );
}

export default App;
