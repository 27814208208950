import { servicesDatas } from "../../datas/servicesDatas"

import './homeServices.css'

import { HomeService } from "../../components"

function HomeServices () {
  return(
    <section className="nos-services">
      <span className="nos-services__title"><h1>UN UNIVERS 4.0 ... POUR VOUS !</h1></span>
      <div className="services">
      {servicesDatas.map(({id, title, icon, miniDescription, cover}) => 
        <HomeService key={id} id={id} title={title} icon={icon} description={miniDescription} cover={cover}/>
        )}
      </div>
      {/*<Link className="services-cta" title="START" to='/command'>COMMENCER</Link>*/}
    </section>
  )
}

export default HomeServices
