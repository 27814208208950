import React, { useEffect } from 'react'

import './about.css'

import {Partners, Story, Team, Testimonies} from '../../containers'

import aboutBannerCover from '../../assets/aboutBannerCover.jpg'
import teamImage from '../../assets/teamImage.jpg'
import historyImage from '../../assets/historyImage.jpg'
import testimoniesImage from '../../assets/testimoniesImage.jpg'
import partnersImage from '../../assets/partnersImage.jpg'
import {AiOutlineTeam} from 'react-icons/ai'
import {BsStopwatch} from 'react-icons/bs'
import {BiHappyBeaming}  from 'react-icons/bi'
import {FaRegHandshake} from 'react-icons/fa'
import { Link } from 'react-router-dom'


const About = () => {
  
  useEffect(() => {
    document.title = "À Propos | D'YVIX"
  }, []);

  return (
    <div className='about'>

      <div className='about__menu'>
        <a href="#story"><BsStopwatch/><span>HISTOIRE</span></a>
        <a href="#team"><AiOutlineTeam/><span>ÉQUIPE</span></a>    
        <a href="#testimonies"><BiHappyBeaming/><span>CLIENTS</span></a>  
        <a href="#partners"><FaRegHandshake/><span>PARTENAIRES</span></a>  
      </div>

      <div className="about__banner">
        <img src={aboutBannerCover} alt="" />
        <div className='about__banner-container'>
          <h1>POURQUOI NOUS FAIRE CONFIANCE ?</h1>
          <p>Tout Ce Que Nous Sommes, Nous Le Sommes Pour VOUS <div></div></p>
        </div>
      </div>

      <section className='about-content'>
        {/*<section id='story'>
          <div className="about__pusher"></div>
          <section className="about-story about-section" >
            <div className='about-section__text-side'>
              <div className="about-section-header">
                <div className='underline'><h1>Notre Histoire</h1></div>
                <p>DYVIX, c'est toute une histoire</p>
              </div>
              <img src={historyImage} alt="" />
            </div>
            <Story />
          </section>
        </section>*/}

        <section id='team'>
          <div className="about__pusher"></div>
          <section className="about-team about-section reversed" >
            <div className='about-section__text-side'>
              <div className='about-section-header'>
                <div className='underline'><h1>Notre Équipe</h1></div>
                <p>DYVIX, c'est une équipe de personnes unies et talentueuses !</p>
              </div>
              <img src={teamImage} alt="team" />
            </div>
            <Team />
          </section>
        </section>
          
        <section id='testimonies'>
          <div className="about__pusher"></div>
          <section className="about-testimonies about-section">
            <div className='about-section__text-side'>
              <div className="about-section-header">
                <div className='underline'><h1>Ils nous font Confiance</h1></div>
                <p>DYVIX, c'est la satisfaction de ses clients avant tout !</p>
              </div>
              <img src={testimoniesImage} alt="testimonies" />
            </div>
            <Testimonies />
          </section>
        </section>
          
        {/*<section id='partners'>
          <div className="about__pusher"></div>
          <section className="about-partners about-section reversed">
            <div className='about-section__text-side'>
              <div className="about-section-header">
                <div className='underline'><h1>Nos Partenaires</h1></div>
                <p>DYVIX, c'est un partenaire solide pour les meilleurs du marché !</p>
              </div>
              <img src={partnersImage} alt="partners" />
            </div>
            <Partners />
          </section>
        </section>*/}  
      </section>

      <Link className='about-CTA' to='/contact'>Contactez Nous!</Link>
    </div>
  )
}

export default About
