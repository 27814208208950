import networks from '../assets/network.gif';
import folder from '../assets/folder.gif';
import shield from '../assets/shield.gif';
import pattern from '../assets/pattern.gif';
import handshake from '../assets/handshake.gif';
import inbox from '../assets/inbox.gif';


export const servicesDatas = [
  
  {
    id : '1',
    title :  'ADMINISTRATION ET MAINTENANCE DES SYSTEMES ET RESEAUX INFORMATIQUE'.toLowerCase(),
    icon : networks,
    description: 'lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma',
    miniDescription: 'Avec les dernières technologies à la pointe, Restez serein et en Sécurité, et profitez de notre disponibilité. Contrôlez vos dépenses, obtenez une meilleure vue d\'ensemble de votre entreprise, et ce n\'est pas fini...',
    cover : "",
    stats : 5,
    form : [],
  },
  {
    id: '2',
    title : 'VIRTUALISATION, STOCKAGE, SAUVEGARDE ET REPLICATION'.toLowerCase(),
    icon : folder,
    description: 'lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma',
    miniDescription: 'vivez LÉGERS. Virtualiser et économiser de l\'espace, du temps, et de l\'argent. Sécurisez vos données, contribuez au bien être de la planète, et allez encore plus loin...',
    cover : "",
    stats : 5,
    form: []
  },
  {
    id: '3',
    title : 'AUDIT, CONSEIL ET SECURITE INFORMATIQUE'.toLowerCase(),
    icon : shield,
    description: 'lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma',
    miniDescription: 'Rien de plus important que la Sécurité. Protégez vos données et vos appareils contre les virus et les logiciels malveillants, et protegez vous encore plus...',
    cover : "",
    stats : 5,
    form: []
  },
  {
    id: '4',
    title : 'GENIE LOGICIEL'.toLowerCase(),
    icon : pattern,
    description: 'lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma',
    miniDescription: 'Touchez un publique plus large que jamais avec votre propre site web ou application mobile, Simplifiez vous la vie avec votre propre logiciel de gestion personnalisé, et bien plus encore...',
    cover : "",
    stats : 18,
    form: []
  },
  {
    id: '5',
    title : 'ENVIRONNEMENT DE TRAVAIL COLLABORATIF'.toLowerCase(),
    icon : handshake,
    description: 'lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma',
    miniDescription: 'Faites rimer travail collaboratif, synergie et productivité. Nous vous aidons à mettre en place un espace de travail en commun permettant l\'échange et le Partage D\'Informations ainsi que la Gestion De Projet, et faites en encore plus...',
    cover : "",
    stats : 18,
    form: []
  },
  {
    id: '6',
    title : 'ARCHIVAGE NUMERIQUE ET GESTION ELECTRONIQUE DES DOCUMENTS (GED)'.toLowerCase(),
    icon : inbox,
    description: 'lorem ipsum dolor ahmet si, elbra galia dolor spiritus ohma',
    miniDescription: 'Gagnez du temps avec les archives numériques. Stocker et retrouver des documents n\'a jamais été aussi simple. Et ce n\'est que le début...',
    cover : "",
    stats : 18,
    form: []
  }
]