import React from 'react'
import { Link } from 'react-router-dom'
import {servicesDatas} from '../../datas/servicesDatas'
import logo from '../../assets/logo.jpg'
import {AiFillFacebook, AiFillTwitterSquare, AiFillLinkedin} from 'react-icons/ai'
import {GoLocation} from 'react-icons/go'
import './footer.css'

const Footer = () => {

  return (
    <footer>
      <div className='footer__container'>
        <section className="footer-partA">
          <section className='footer__logo'>
            <img src={logo} alt="" />
          </section>
          <h4>Rejoignez nos réseaux sociaux</h4>
          <section className="social-networks">
            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/dyvixitsolutions'><AiFillFacebook className='social-netword__image'/></a>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/dyvix-itsolutions/'><AiFillLinkedin className='social-netword__image'/></a>
            <a target='_blank' rel='noreferrer' href='https://twitter.com/d_yvix'><AiFillTwitterSquare className='social-netword__image'/></a>
          </section>
          <h4>Appelez Nous !</h4>
          <section className='footer-partA__phone'><div><p>(+237)</p><p>242 796 818</p><p> 674 294 455</p></div></section>
        </section>

        <section className="footer-partB">
          <h4 className="footer-partB-header">Menu</h4>
          <section className="footer-partB__container">
            <Link className='footer-part__container-link' to='/'>Accueil</Link>
            <Link className='footer-part__container-link' to='/news'>Actualités</Link>
            <Link className='footer-part__container-link' to='/about'>Pourquoi D'yvix ?</Link>
            <Link className='footer-part__container-link' to='/contact'>Contacts</Link>
          </section>
        </section>

        <section className="footer-partC">
          <h4 className="footer-partC__header">Services</h4>
          <section className="footer-partC__container">
            {
              servicesDatas.map((service, index) =>
                <div className='footer-part__container-link footer__service' key={service.id}><Link to={`/service/${service.id}#service-${service.id}`}>{service.title}</Link><div className='bar'/></div>
              )
            }
          </section>
        </section>

        <section className="footer-partD">
          <h1>Rejoignez notre newsletter</h1>
          <form action="" className="footer-newsletter">
            <input type="text" placeholder="Adresse e-mail"/>
            <input type="submit" value="M'inscrire"/>
          </form>
        </section>
      </div>
      <section className="footer__bottom">
        <section className='footer__bottom-left'>
          <div className="copywriting">2022 © Copywrite D'YVIX IT SOLUTIONS</div>
          <div className='location'><GoLocation/><div>Yaoundé 5 ESSOS, Descente Avenue Germaine, Rue 1.270</div></div>
        </section>
        <section className='footer__bottom-right'><Link  className='politique' to='/politics'>Notre Politique</Link></section>
      </section>
    </footer>
  )
}

export default Footer
