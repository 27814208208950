import React from 'react'
import {Link} from 'react-router-dom'

import './homeService.css'

const HomeService = ({id, title, icon, description, cover}) => {
  return (
    <Link className="service" to={`/service/${id}`}>
      <div className="service-header">
        <div className="service-icon"><img src={icon} alt=""/></div>
        <h3 className="service-title">{title}</h3>
      </div>
      <div className="service-description">
        {description}
      </div>
    </Link>
  )
}

export default HomeService
