import React, { useState } from 'react'
import styled from 'styled-components'
import Slide  from '../slide/Slide'

import './slider.css'


const SlideWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Navigators = styled.div`
  font-size: 5rem;
  display: flex;
  margin-top: 10px;
`

const Navigator = styled.div` 
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 15px;
  cursor: pointer;
  ${({isActive}) => isActive ?
  `
    background-color: green;
  ` :
  `
    border: solid black 1px;
  `
}
`

const Slider = ({sliderDatas, sliderId}) => {


  const [currentSlide, setCurrentSlide] = useState(sliderDatas[0])

  return (
    <div className='about-section-content slider' id={sliderId}>
      <SlideWrapper>
        <Slide title={currentSlide.title} subTitle={currentSlide.subTitle} text={currentSlide.text} image={currentSlide.image} sliderId={sliderId}/>
      </SlideWrapper>
      <Navigators>
        {sliderDatas.map((item, index) =>
          <Navigator key={`${item.name}-${index}`} onClick={() => setCurrentSlide(item)} isActive={currentSlide === item}></Navigator>
        )}
      </Navigators>
    </div>
  )
}

export default Slider
