import customer1 from '../assets/customer1.jpg';
import customer2 from '../assets/customer3.jpg';
import customer3 from '../assets/customer1.jpg';
import customer4 from '../assets/customer3.jpg';
import customer5 from '../assets/customer1.jpg';
import customer6 from '../assets/customer3.jpg';


export const testimoniesDatas = [
  {
    title: "name1",
    subTitle: "CEO at BDR",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : customer1
  },
  {
    title: "name2",
    subTitle: "CEO at LINK CORP",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : customer2
  },
  {
    title: "name3",
    subTitle: "MARKETING CHIEF at SHIKO SHOP",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : customer3
  },
  {
    title: "name4",
    subTitle: "CARPENTER ARTIST",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : customer4
  },
  {
    title: "name5",
    subTitle: "MUSICIAN",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : customer5
  },
  {
    title: "name6",
    subTitle: "SALES PERSON",
    text: "lorem ipusn dolor sit. lorem ipusn dolor sitlorem ipusn dolor sitlorem ipusn dolor sit.",
    image : customer6
  }
];