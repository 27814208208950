import {GiHamburgerMenu} from 'react-icons/gi'
import {MdOutlineClose} from 'react-icons/md'
import {BsFillInfoSquareFill} from 'react-icons/bs'

import './header.css'
import logo from '../../assets/logo.jpg'
import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'

import { ServicesMenu } from '../../components'

function Header(){

const NavMenu =  ({setToggleMenu}) => (
  <nav>
    <NavLink 
      to='/' 
      onClick={() => setToggleMenu(false)}
      style={({isActive}) => ({})}
      className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link unActive-link')}
    >
      <Link to="/">
        Home
      </Link>
    </NavLink>
    <NavLink 
      to='/contact'
      onClick={() => setToggleMenu(false)} 
      style={({isActive}) => ({})}
      className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link unActive-link')}
    >
      <Link to="/contact">
        Contacts
      </Link>
    </NavLink>
    <ServicesMenu setToggleMenu={setToggleMenu}/>
    <NavLink 
      to='/news'
      onClick={() => setToggleMenu(false)}
      style={({isActive}) => ({})}
      className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link unActive-link')}
    >
      <Link to="/news">
        Actualités
      </Link>
    </NavLink>
    <NavLink 
      to='/about'
      onClick={() => setToggleMenu(false)} 
      style={({isActive}) => ({})}
      className={({isActive}) => (isActive ? 'nav-link about-button active-link' : 'nav-link about-button unActive-link')}
    >
      <Link to="/about">
        À propos
      </Link>
    </NavLink>
  </nav>
)

  const [toggleMenuOn, setToggleMenu] = useState(false)

  return(
    <header id="reduced-header">
      <Link className="logo" to='/'> <img src={logo} alt="logo"/><span>D'YVIX it solutions</span></Link>
      <NavMenu />
      <div className='nav-link_emph'><Link className="nav-link" to='/about'>POURQUOI D'YVIX ?</Link></div>
      <div className='small-nav'>
        <Link className='aboutIcon' to='/about'><BsFillInfoSquareFill/></Link>
        <div className='toggleMenu'>
          {!toggleMenuOn ?
            <div className="toggleMenuButton openToggleButton" onClick={() => setToggleMenu(true)}><GiHamburgerMenu/></div> :
            <div className="toggleMenuButton closeToggleButton" onClick={() => setToggleMenu(false)}><MdOutlineClose/></div>
          }
          {toggleMenuOn &&
            <NavMenu setToggleMenu={setToggleMenu}/>
          }

        </div>
      </div>
      
    </header>

  )
}

export default Header