import './banner.css'

function Banner({itemA, itemB, itemC}) {

  return(
  <div className="banner">
    <div className="banner-partA">
      <h2>{itemA}</h2>
      <p className="banner-description">{itemB}</p>
      <p className="banner-slogang">{itemC}</p>
    </div>
    <div className="banner-partB">
      <div className="img"> </div>
    </div>
  </div>
  )
}

export default Banner